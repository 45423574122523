<template>
  <v-dialog
    :value="true"
    max-width="480"
    @click:outside="$emit('close')"
  >
    <v-card tile elevation="0">
      <v-app-bar flat color="primary" class="mb-6">
        <v-icon dark left>mdi-paperclip</v-icon>
        <v-toolbar-title class="text-h6 white--text pl-0">
          Rendszám kártyához kapcsolása
        </v-toolbar-title>
      </v-app-bar>

      <v-card-text>
        <v-card color="muted" class="pa-4" elevation="0">
          Kiválasztott rendszám:
          <v-simple-table dense class="mt-2 muted">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-left pl-0 font-weight-bold" width="1">Név</td>
                  <td>{{ plate.card_owner.name }}</td>
                </tr>
                <tr>
                  <td class="text-left pl-0 font-weight-bold">Rendszám</td>
                  <td>{{ plate.number }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-autocomplete
          v-model="select"
          :loading="loading"
          :items="cards"
          :item-text="itemText"
          item-value="card_number"
          class="mt-8"
          filled
          elevation="0"
          hide-details
          label="Kártya kiválasztása"
        >
          <template v-slot:item="{ item }">
            {{ item.card_number }} ({{ item.owner_name }})
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          @click="$emit('close')"
        >
          Mégse
        </v-btn>

        <v-btn
          color="primary"
          text
          @click="$emit('close')"
        >
          Mentés
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    plate: {
      type: Object,
      default: () => null
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      cards: [],
    };
  },

  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
  },

  async mounted() {
    const response = await this.$http.post('cards/list');
    this.cards = response.cards;
  },

  methods: {
    reset() {
      this.loading = false;
      this.show = false;
      this.items = [];
      this.search = null;
      this.select = null;
    },

    itemText(card) {
      return `${ card.card_number } (${ card.owner_name })`;
    },

    querySelections (v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.cards.filter(e => {
          return (e.card_number || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        })
        this.loading = false
      }, 500);
    },
  },
}
</script>
